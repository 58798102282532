'use client';

import { SUPPORT_EMAIL } from '@company/common/constants';
import { getDomainFromEmail } from '@company/common/lib';
import { logger } from '@company/common/logger';
import { createSupabaseBrowserClient } from '@company/supabase/client/browser';
import { Button, ExternalLink, toaster } from '@company/ui/components';
import { KeyRoundIcon } from '@company/ui/icons';
import { Trans } from '@lingui/react/macro';
import React from 'react';

interface SamlSignInButtonProps {
  userEmail: string;
  organization: {
    samlProviderId: string;
    name: string;
    logoUrl: string;
  };
}

export const SamlSignInButton = ({ userEmail, organization }: SamlSignInButtonProps) => {
  const [isLoading, setIsLoading] = React.useState(false);
  const onClick = async () => {
    setIsLoading(true);
    const supabase = createSupabaseBrowserClient();
    const response = await supabase.auth.signInWithSSO({
      domain: getDomainFromEmail(userEmail),
      options: {
        redirectTo: `${process.env.NEXT_PUBLIC_BASE_URL}/auth/confirm-sso`
      }
    });

    if (response.error) {
      toaster.create({
        type: 'error',
        title: <Trans>Something went wrong</Trans>,
        description: (
          <Trans>
            Please please reach out to{' '}
            <ExternalLink href={`mailto:${SUPPORT_EMAIL}`}>{SUPPORT_EMAIL}</ExternalLink>.
          </Trans>
        )
      });
      logger.error(response.error);
    } else {
      window.open(response.data.url, '_self');
    }
    setIsLoading(false);
  };

  return (
    <Button
      variant={'outline'}
      w={'full'}
      onClick={onClick}
      loading={isLoading}
      disabled={isLoading}
      loadingText={<Trans>Redirecting...</Trans>}
    >
      <KeyRoundIcon />
      <Trans>{organization.name} Login (SSO)</Trans>
    </Button>
  );
};
